<template>
    <b-container fluid class="blockAB4">
        <b-container>
            <b-row class="d-flex justify-content-center">
                <p class="block3-p1 ">Certifications</p>
            </b-row>
            <b-row class="d-flex justify-content-center">
                <h2 class="block2-h2">Certifications and registrations</h2>
            </b-row>
            <b-row class="mt-5">
                <b-col md="4"  class="p-3 p-md-0">
                    <img src="../img/sert1.jpg" class="pdcolblocks3" @click="showModal" style="width:100%" alt="">
                </b-col>
                <b-col md="8" class="blockAB4-M pr-md-0" >
                    <img src="../img/sert2.jpg" class="pdcolblocks3" @click="showModal2" style="width:100%" alt="">
                </b-col>
            </b-row>
        </b-container>
        <modalserf></modalserf>
        <modalserf2></modalserf2>
    </b-container>
</template>
<script>
import modalserf from "../components/modal_sert1.vue"
import modalserf2 from "../components/modal_sert2.vue"
export default {
  components:{modalserf,modalserf2},
    methods: {
    showModal() {
        if(window.screen.width > 600){
            this.$root.$emit('bv::show::modal', 'modal-sert', '#modal-sert')
        }
    },
     showModal2() {
        if(window.screen.width > 600){
            this.$root.$emit('bv::show::modal', 'modal-sert2', '#modal-sert2')
        }
    }
    }
}
</script>