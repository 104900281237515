<template>
  <div>
    <b-modal
      id="modal-sert"
      ref="modal-sert"
      centered
      hide-footer
      hide-header
      size="lg"
    >
     <b-row >
        <b-button @click="hideModal" class="close ml-auto mr-3 mb-3"  >
                         <b-icon icon="plus" class="closeplus"></b-icon>
                      </b-button>
      </b-row>
       <img src="../img/sert1.jpg"  style="width:100%" alt="">
     </b-modal>
  </div>
</template>
<script>
export default {
    methods: {
    hideModal() {
      this.$refs["modal-sert"].hide();
    },
  },
}
</script>