<template>
  <div style="overflow:hidden">
    <TopNavBar></TopNavBar>
    <TopNavBar2 id="navbar2" class="tnb2none"></TopNavBar2>
    <blockAB1></blockAB1>
    <blockAB2></blockAB2>
    <blockAB3></blockAB3>
    <blockAB4></blockAB4>
    <blockAB5></blockAB5>
    <blockAB6></blockAB6>
    <block8></block8>
    <form1></form1>
    <Footer1></Footer1>
  </div>
</template>

<script>
import TopNavBar from '../components/TopNavBar.vue'
import TopNavBar2 from '../components/TopNavBar2.vue'
import blockAB1 from '../components/blockAB1.vue'
import blockAB2 from '../components/blockAB2.vue'
import blockAB3 from '../components/blockAB3.vue'
import blockAB4 from '../components/blockAB4.vue'
import blockAB5 from '../components/blockAB5.vue'
import blockAB6 from '../components/blockAB6.vue'
import form1 from '../components/form.vue'
import block8 from '../components/block8.vue'
import Footer1 from '../components/footer.vue'
export default {
  metaInfo() {
    return {
      title: this.MetaData['title'], // set a title
      titleTemplate: this.MetaData['titleTemplate'], // %s  required
      htmlAttrs: this.MetaData['htmlAttrs'],
      meta: this.MetaData['meta'],
      link: [
        { rel: 'canonical', href: 'https://pennlogisticsllc.com/about-us' }
      ]
    }
  },
  data() {
    return {
      metaData: {

        en: {
          Homepage: {
            title: ' About Pennsylvania Logistics | Freight Transportation Company', // set a title
            titleTemplate: '', // %s  required
            htmlAttrs: {
              lang: "en",
              amp: undefined // "amp" has no value
            },
            meta: [
              {
                'name': 'keywords',
                'content': 'freight transportation services, ftl shipping, ltl shipping, trucking, truckload, transportation company',
              },
              {
                'name': 'description',
                'content': 'Pennsylvania Logistics is a one-stop provider for all of your Freight Transportation needs. 6 years of impeccable work. More than 500 available trucks. 24/7 support.',
              },
              {
                property: 'og:url',
                content: 'https://pennlogisticsllc.com/about-us',
                 vmid: 'og:url'
              },
              {
                property: 'og:title',
                content: 'About Pennsylvania Logistics | Freight Transportation Company',
                vmid: 'og:title'
              },
              {
                property: 'og:description',
                content: 'Pennsylvania Logistics is a one-stop provider for all of your Freight Transportation needs. 6 years of impeccable work. More than 500 available trucks. 24/7 support.',
                vmid: 'og:description'
              },
              {
                property: 'og:type',
                content: 'website',
                vmid: 'og:type'
              },
              {
                property: 'og:image',
                content: '../img/fon-blockAB1.webp',
                vmid: 'og:image'
              },


            ],
            link: [
              { rel: 'canonical', href: 'https://pennlogisticsllc.com/about-us' }
            ]
          },
        },
      },
    }
  },
  name: 'Home',
  components: {
    TopNavBar, blockAB1, form1, Footer1, block8, blockAB2, blockAB3, blockAB4, blockAB5, blockAB6, TopNavBar2
  },
  computed: {
    MetaData() {
      return this.metaData['en']['Homepage']
    }
  },
  mounted() {
    this.anim()
  }
}
</script>
